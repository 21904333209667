export default class BrockmanForYou {
  constructor(args) {
    this.csrf = args.csrf;
    this.site = args.site;
    this.forYouEndpoint = args.for_you_endpoint;
    this.forYouStartEndpoint = args.for_you_start_endpoint;
    this.hasPersonalisedHomepageEndpoint =
      args.has_personalised_homepage_endpoint;
    this.setPersonalisedHomepageEndpoint =
      args.set_personalised_homepage_endpoint;
    this.loggedIn = args.logged_in;
    this.loading = false;
    this.homepageLoaded = false;
    this.mypop = args.mypop;
    this.interestsStorage = args.interests_storage;
    this.loc = args.loc || {
      error: 'Something went wrong.',
    };
    this.initialize();
  }

  initialize() {
    this.loadingSpinner = document.querySelector('#for-you-loading-spinner');
    this.content_wrapper = document.querySelector('.for-you-content-wrapper');
  }

  loadForYouEntry() {
    // if logged out - check if storage is set, go to first step
    // if logged in, check if storage is set, then check if completed homepage, then go to first step
    if (this.homepageLoaded) {
      return;
    }
    this.startLoad();
    this.interestsStorage.getFollows().then((follows) => {
      if (this.loggedIn) {
        this.loadForYouEntryLoggedIn(follows);
      } else {
        this.loadForYouEntryLoggedOut(follows);
      }
    });
  }

  loadForYouEntryLoggedIn(follows) {
    this.userHasCustomisedHomepage().then((result) => {
      if (result) {
        this.loadForYouHomepage(follows);
      } else {
        this.loadForYouStep(this.forYouStartEndpoint);
      }
    });
  }

  loadForYouEntryLoggedOut(follows) {
    if (follows.length > 0) {
      this.loadForYouHomepage(follows);
    } else {
      this.loadForYouStep(this.forYouStartEndpoint);
    }
  }

  userHasCustomisedHomepage() {
    const url = new URL(
      this.hasPersonalisedHomepageEndpoint,
      window.location.origin,
    );
    url.searchParams.append('site', this.site);
    return fetch(new Request(url), {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((markup) => markup.json())
      .then((data) => {
        if ('personalised_homepage' in data) {
          return data.personalised_homepage;
        }
        return false;
      });
  }

  setPersonalisedHomepage() {
    const formData = new FormData();
    formData.append('site', this.site);
    fetch(new Request(this.setPersonalisedHomepageEndpoint), {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': this.csrf,
      },
      body: formData,
    });
  }

  getSelectedFromForm(form) {
    const selectedInputs = [];
    const selectedTargets = form.querySelectorAll('input:checked');
    for (let i = 0; i < selectedTargets.length; i += 1) {
      const target = selectedTargets[i];
      selectedInputs.push(target.value);
    }
    return selectedInputs;
  }

  clickNext(e) {
    const button = e.target;
    const { nextUrl } = button.dataset;
    const url = new URL(nextUrl, window.location.origin);
    const form = this.content_wrapper.querySelector('.for-you-form');
    if (form) {
      const inputs = this.getSelectedFromForm(form);
      url.searchParams.append(form.dataset.key, inputs);
    }
    this.content_wrapper.innerHTML = '';
    this.startLoad();
    this.loadForYouStep(url);
  }

  clickBack(e) {
    const button = e.target;
    const { backUrl } = button.dataset;
    const url = new URL(backUrl, window.location.origin);
    this.content_wrapper.innerHTML = '';
    this.startLoad();
    this.loadForYouStep(url);
  }

  checkStatus(response) {
    if (response.ok) return response;
    throw new Error('Response not OK');
  }

  renderError() {
    this.content_wrapper.innerHTML = `<div class="alert error">${this.loc.error}</div>`;
    this.stopLoad();
  }

  loadForYouStep(url) {
    fetch(new Request(url), {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response) => this.checkStatus(response))
      .then((response) => response.text())
      .then((html) => {
        this.content_wrapper.innerHTML = html;
        this.stopLoad();
        const button = this.content_wrapper.querySelector('.for-you-button');
        if (button.dataset.action === 'end') {
          button.addEventListener('click', this.followFromForm.bind(this));
        } else {
          button.addEventListener('click', this.clickNext.bind(this));
        }
        const backButton = this.content_wrapper.querySelector('button.back');
        if (backButton) {
          backButton.addEventListener('click', this.clickBack.bind(this));
        }
      })
      .catch(() => this.renderError());
  }

  followFromForm() {
    const form = this.content_wrapper.querySelector('form');
    const follows = this.getSelectedFromForm(form);
    this.interestsStorage.followUUIDs(follows).then(() => {
      this.loadForYouHomepage(follows);
    });
  }

  loadForYouHomepage(follows) {
    this.startLoad();
    this.content_wrapper.innerHTML = '';
    this.getForYouHomepageHTML(follows)
      .then((response) => this.checkStatus(response))
      .then((response) => response.text())
      .then((html) => {
        this.content_wrapper.innerHTML = html;
        if (this.loggedIn) {
          this.setPersonalisedHomepage();
        }
        this.mypop.loadForElement(this.content_wrapper);
        this.homepageLoaded = true;
        this.stopLoad();
      })
      .catch(() => this.renderError());
  }

  getForYouHomepageHTML(follows) {
    if (this.loggedIn) {
      return this.getForYouHomepageHTMLLoggedIn(follows);
    }
    return this.getForYouHomepageHTMLLoggedOut(follows);
  }

  getForYouHomepageHTMLLoggedOut(follows) {
    const url = new URL(this.forYouEndpoint, window.location.origin);
    url.searchParams.append('tags', follows);
    return fetch(new Request(url.toString()), {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  }

  getForYouHomepageHTMLLoggedIn(follows) {
    const formData = new FormData();
    formData.append('tags', follows.toString());
    formData.append('site', this.site);
    return fetch(new Request(this.forYouEndpoint), {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': this.csrf,
      },
      body: formData,
    });
  }

  startLoad() {
    this.loadingSpinner.style.display = 'block';
  }

  stopLoad() {
    this.loadingSpinner.style.display = 'none';
  }
}
