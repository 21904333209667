/**
 * Represents a set of tabs with functionality to switch between them.
 */
export default class BrockmanTabs {
  /**
   * @constructor
   * @param {string} selector - The selector for the tab buttons. Default is
   * '.tabbed_button'.
   * @param {HTMLElement} container - The container element for the tab buttons.
   * Default is `document`.
   */
  constructor({ selector, container }) {
    this.container = container || document;
    const _selector = selector || '.tabbed_button';
    this.buttons = this.container.querySelectorAll(_selector);
    this.callback = null;
  }

  /**
   * Initializes the tab functionality.
   */
  run() {
    this.buttons.forEach((button, index) => {
      if (index === 0) this._setActive(button);
      else this._setInactive(button);
      const { tab } = button.dataset;
      if (tab) button.addEventListener('click', () => this.click(button));
    });
  }

  /**
   * Retrieves the corresponding tab element for a tab button.
   * @param {HTMLElement} button - The tab button.
   * @returns {HTMLElement|null} The corresponding tab element, or null if not
   * found.
   */
  _getTab(button) {
    const { tab } = button.dataset;
    if (!tab) return null;
    return this.container.querySelector(tab);
  }

  /**
   * Sets a tab button as active and shows its corresponding tab.
   * @param {HTMLElement} button - The tab button to set as active.
   */
  _setActive(button) {
    const tab = this._getTab(button);
    if (!tab) return;
    tab.classList.remove('hidden');
    button.classList.add('active');
    const count = button.querySelector('.tabbed_button_count');
    if (count) count.remove();
    if (this.callback) this.callback(tab);
  }

  /**
   * Sets a tab button as inactive and hides its corresponding tab.
   * @param {HTMLElement} button - The tab button to set as inactive.
   */
  _setInactive(button) {
    const tab = this._getTab(button);
    if (!tab) return;
    tab.classList.add('hidden');
    button.classList.remove('active');
  }

  /**
   * Sets all tab buttons as inactive and hides their corresponding tabs.
   */
  _setAllInactive() {
    this.buttons.forEach((button) => this._setInactive(button));
  }

  /**
   * Registers a callback function to be called when a tab is activated.
   * @param {Function} callback - The callback function.
   */
  registerCallback(callback) {
    this.callback = callback;
  }

  /**
   * Handles the click event on a tab button.
   * @param {HTMLElement} button - The clicked tab button.
   */
  click(button) {
    this._setAllInactive();
    this._setActive(button);
  }

  /**
   * Activates a tab by its index.
   * @param {number} index - The index of the tab to activate.
   */
  setTab(index) {
    const button = this.buttons[index];
    if (button) this.click(button);
  }
}
